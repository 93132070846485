import { LicenseManager } from 'ag-grid-enterprise';
import React from 'react';
import { useSelector } from 'react-redux';
import 'uu5g04/bricks';

import webModuleRegister from '../../../webModuleRegister';
import { LsiContext, UserInfoContext } from '../../../packages/common/contexts';
import InitializationStatus from '../../../packages/common/utils/InitializationStatus';

import App from './App';
import AppInitializer from './AppInitializer';
import './App.scss';
import './App.print.scss';

const propTypes = {};
const defaultProps = {};

const AppConnector = () => {
  const initializationDataList = webModuleRegister.getInitializationDataList();
  const initStatusList = useSelector(state => initializationDataList.map(initData => initData.statusSelector(state)));

  const userInfoList = useSelector(state => webModuleRegister.getUserInfoDataList().map(userInfoData => userInfoData.selector(state)));
  const userInfo = userInfoList.find(ui => ui != null); // Take first userInfo - they should all have the same content!

  const locale = userInfo?.language;
  const lsi = React.useMemo(
    () => LsiContext.getLsiContextValue(locale),
    [locale]
  );

  React.useEffect(
    () => {
      if (userInfo?.metaData != null) {
        LicenseManager.setLicenseKey(userInfo.metaData.agGridKey);
      }
    },
    [userInfo]
  );

  if (initStatusList.some(status => !InitializationStatus.isFinalStatus(status)) || initStatusList.every(status => status === InitializationStatus.FAILED)) {
    return (
      <AppInitializer />
    );
  }

  return (
    <UserInfoContext.Provider value={userInfo} >
      <LsiContext.Provider value={lsi}>
        <App />
      </LsiContext.Provider>
    </UserInfoContext.Provider>
  );
};

AppConnector.propTypes = propTypes;
AppConnector.defaultProps = defaultProps;

export default AppConnector;