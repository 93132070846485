import React from 'react';
import PropTypes from 'prop-types';
import { ChangeDetectionStrategyType } from 'ag-grid-react';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import PriceGuaranteeReportPageTypeContext from '../PriceGuaranteeReportPageTypeContext';
import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';

import { PriceGuaranteeReportRow } from './rowModel/PriceGuaranteeReportRow';
import { columnTypes, getAutoGroupColumnDef, getColumnDefinitions } from './PriceGuaranteeReportTable.columnDefs';
import { COLUMN_IDS } from './PriceGuaranteeReportTable.columnIds';
import { getContextMenuItems } from './PriceGuaranteeReportTable.contextMenu';
import { getDataPath, getRowClass } from './PriceGuaranteeReportTable.helpers';

import './PriceGuaranteeReportTable.scss';

const propTypes = {
  currency: PropTypes.string,
  onShowCommissionValidator: PropTypes.func,
  pgReportRowList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeReportRow))
};

const defaultProps = {
  currency: null,
  onShowCommissionValidator: null,
  pgReportRowList: null
};

const PriceGuaranteeReportTable = React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);
  const pageType = React.useContext(PriceGuaranteeReportPageTypeContext);

  const { pgReportRowList, currency, onShowCommissionValidator } = props;

  const tableContext = React.useMemo(
    () => ({
      onShowCommissionValidator: onShowCommissionValidator
    }),
    [onShowCommissionValidator]
  );

  const handleRowDataChanged = React.useCallback(
    (rowDataChangedEvent) => {
      const filterInstance = rowDataChangedEvent.api.getFilterInstance(COLUMN_IDS.PGI_PGID);

      const values = filterInstance.getValues();
      filterInstance.setModel({ filterType: 'set', values: values.filter(v => v != null) });

      rowDataChangedEvent.api.onFilterChanged();
    },
    []
  );

  const dealerTableProps = PriceGuaranteeReportPageType.isDealer(pageType)
    ? {
      getRowClass: getRowClass,
      onRowDataChanged: handleRowDataChanged
    }
    : {};

  return (
    <OvexAGTable
      agContext={tableContext}
      autoGroupColumnDef={getAutoGroupColumnDef(lsi)}
      className='ovex-pg-PriceGuaranteeReportTable'
      columnDefs={getColumnDefinitions(lsi, currency, pageType)}
      columnTypes={columnTypes}
      enableRangeSelection
      getContextMenuItems={getContextMenuItems}
      getTreeDataPath={getDataPath}
      height='580px'
      ref={ref}
      rowData={pgReportRowList}
      rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
      {...dealerTableProps}
    />
  );
});

PriceGuaranteeReportTable.propTypes = propTypes;
PriceGuaranteeReportTable.defaultProps = defaultProps;

export default PriceGuaranteeReportTable;
