import { getCellData } from '../../../../common/components/ag-grid/OvexAGTable';
import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { isString, parseNumber } from '../../../../common/utils/helpers';
import { quarterPeriodFormatter } from '../../../../common/utils/formatters';
import { RestrictionTypeEnum } from '../../../utils/const';

import RestrictionRowTypeEnum from './rowModel/RestrictionRowTypeEnum';
import RestrictionRow from './rowModel/RestrictionRow';
import { convertWeekDetailListToViewStructure, weekTableHeaderName } from './restrictionUtils';
import { getContextMenuEditMode, getContextMenuReadOnlyMode } from './RestrictionsTableContextMenuItems';
import RestrictionWeekCell from './rowModel/RestrictionWeekCell';
import { setCellData } from '../../../../common/components/ag-grid/OvexAGTable/ovexAgTableUtils';

export const RESTRICTION_COLUMN_IDS = {
  MODEL_GROUP: 'modelGroup',
  RESTRICTION_TYPE: 'restrictionType',
  MODEL_MASK: 'modelMask',
  COLOUR: 'colour',
  INTERIOR: 'interior',
  PR_NUMBER: 'prNumber'
};

class RestrictionsTableData {

	/**
	 * Definice sloupců pro restrikce.
	 *
	 * @param lsi
	 */
	restrictionColumns = (lsi) => {
	  return [{
	    groupId: 'restrictionHeader',
	    headerName: '',
	    marryChildren: true,
      children: [{
        children:
          [{
            children: [
              {
                colId: RESTRICTION_COLUMN_IDS.MODEL_GROUP,
                field: 'modelGroup',
                headerName: lsi.getLSIItem('PVT.TABLE_HEADER.GROUP'),
                width: 90,
                type: ['restrictionDefinitionColumn'],
                rowGroup: true,
                sortable: false,
                cellClass: ['RestrictionsTable-column--modelGroup', EXCEL_STYLES_IDS.STRING]
              },
              {
                colId: RESTRICTION_COLUMN_IDS.RESTRICTION_TYPE,
                field: 'restrictionType',
                headerName: lsi.getLSIItem('PVT.TABLE_HEADER.RESTRICTION_TYPE'),
                width: 70,
                type: ['restrictionDefinitionColumn'],
                cellClass: EXCEL_STYLES_IDS.STRING,
                valueFormatter: p => this._restrictionTypeFormatter(p, lsi),
                filterParams: { valueFormatter: p => this._restrictionTypeFilterFormatter(p, lsi) },
                headerTooltip: lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_TYPE')
              },
              {
                colId: RESTRICTION_COLUMN_IDS.MODEL_MASK,
                field: 'modelMask',
                headerName: lsi.getLSIItem('PVT.TABLE_HEADER.MASK'),
                width: 80,
                type: ['restrictionDefinitionColumn'],
                cellClass: EXCEL_STYLES_IDS.STRING
              },
              {
                colId: RESTRICTION_COLUMN_IDS.COLOUR,
                field: 'colour',
                headerName: lsi.getLSIItem('PVT.TABLE_HEADER.COLOUR'),
                width: 80,
                type: ['restrictionDefinitionColumn'],
                cellClass: EXCEL_STYLES_IDS.STRING
              },
              {
                colId: RESTRICTION_COLUMN_IDS.INTERIOR,
                field: 'interior',
                headerName: lsi.getLSIItem('PVT.TABLE_HEADER.INTERIOR'),
                width: 85,
                type: ['restrictionDefinitionColumn'],
                cellClass: EXCEL_STYLES_IDS.STRING
              },
              {
                colId: RESTRICTION_COLUMN_IDS.PR_NUMBER,
                field: 'prNumber',
                headerName: lsi.getLSIItem('PVT.TABLE_HEADER.PR_NUMBER'),
                width: 90,
                type: ['restrictionDefinitionColumn'],
                cellClass: EXCEL_STYLES_IDS.STRING
              }

            ]
          }]

      }]
	  }];
	};

  static columnTypes = {
    restrictionDefinitionColumn: {
      pinned: 'left',
      sortable: true,
      filter: true,
      filterParams: { newRowsAction: 'keep' },
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      cellClassRules: {
        'RestrictionsTable-column-definition--addedRestriction': (params) => {
          return !params.node.group && params.data.added && !params.data.isNew();
        },
        'RestrictionsTable-column-definition--deletedInFuture': (params) => {
          return !params.node.group && params.data.deletedInFuture;
        },
        'RestrictionsTable-column-definition--newRestriction': (params) => {
          return !params.node.group && params.data.isNew();
        }
      }
    }
  };

  /**
   * Definice sloupců pro týdení výrobní kapacitu.
   *
   * @param {Array.<model/WeekDetailDTO>} weekDetailList
   * @param lsi
   */
  restrictionWeekColumns = (weekDetailList, lsi) => {
    return convertWeekDetailListToViewStructure(weekDetailList).map(yearStructure => {
      return {
        groupId: yearStructure.groupId,
        headerName: yearStructure.year,
        headerClass: 'RestrictionsTable-column-headerSeparator-quarter',
        marryChildren: true,
        children: yearStructure.subItemList.map(quarterStructure => ({
          groupId: quarterStructure.groupId,
          headerName: quarterStructure.year + quarterPeriodFormatter(quarterStructure.quarter),
          headerClass: 'RestrictionsTable-column-headerSeparator-quarter',
          marryChildren: true,
          children: quarterStructure.subItemList.map(monthStructure => ({
            groupId: monthStructure.groupId,
            headerName: lsi.getLSIItem(`COMMON.ENUM_LABELS.MONTH.${monthStructure.month}`) + ' ' + monthStructure.year,
            headerClass: (headerClassParams) => {
              return headerClassParams.colDef.ovLastMonthOfQuarter ? 'RestrictionsTable-column-headerSeparator-quarter' : 'RestrictionsTable-column-headerSeparator-month';
            },
            marryChildren: true,
            // 'ov' = OVEX3 properties, used to set ccs classes
            ovLastMonthOfQuarter: monthStructure.lastMontOfQuarter,
            children: monthStructure.subItemList.map(weekDetail => ({
              colId: weekDetail.pvtWeek,
              field: weekDetail.pvtWeek,
              headerName: weekTableHeaderName(weekDetail.pvtWeek),
              width: 80,
              suppressMenu: true,
              editable: this._handleCheckEditable,
              headerClass: (headerClassParams) => {
                const headerClasses = ['ovex-ag-grid--th-center'];
                const wd = headerClassParams.colDef.ovWeekDetail;
                wd.lastWeekOfQuarter && headerClasses.push('RestrictionsTable-column-headerSeparator-quarter');
                wd.lastWeekOfMonth && headerClasses.push('RestrictionsTable-column-headerSeparator-month');
                return headerClasses;
              },
              cellClass: (cellClassParams) => {
                const cellClasses = ['ovex-ag-grid--renderer-fill-cell'];
                const wd = cellClassParams.colDef.ovWeekDetail;
                wd.lastWeekOfQuarter && cellClasses.push('RestrictionsTable-column-lastWeekOfQuarter');
                wd.lastWeekOfMonth && cellClasses.push('RestrictionsTable-column-lastWeekOfMonth');
                return cellClasses;
              },
              // cellRenderer: WeekCellRenderer,
              valueGetter: this._handleValueGetter,
              valueParser: this._handleValueParser,
              valueSetter: this._handleValueSetter,
              suppressMovable: true,
              // 'ov' = OVEX3 properties, used to set ccs classes
              ovWeekDetail: weekDetail
            }))
          }))
        }))
      };
    });
  };

  static rowClassRules = {
    'RestrictionsTable-row--quota': (params) => {
      return !params.node.group && RestrictionRowTypeEnum.isModelGroup(params.data.type);
    },
    'RestrictionsTable-row--restriction': (params) => {
      return !params.node.group && RestrictionRowTypeEnum.isRestriction(params.data.type);
    }
  };

  static getContextMenuItems = (params) => {
    if (params.context.editable) {
      return getContextMenuEditMode(params);
    }
    return getContextMenuReadOnlyMode(params);
  };

  static getRowHeight = (params) => {
    if (!params.node.group && RestrictionRowTypeEnum.isModelGroup(params.data.type)) {
      return params.node.rowHeight + 18; // 18 px = line-height of second row (css class WeekCellRenderer-common)
    }
    return params.node.rowHeight;
  };

  _handleCheckEditable = (params) => {
    const { restrictionType, deletedInFuture }  = params.data;
    const cellData = getCellData(params);
    return params.context.editable && !deletedInFuture && (cellData != null || restrictionType === RestrictionTypeEnum.T);
  };

  _handleValueGetter = (params) => {
    const cellData = getCellData(params);
    if (cellData != null) {
      return cellData.getTabValue(params.context.selectedTab);
    }
    return null;
  };

  _handleValueParser = (valueParserParams) => {
    return /^[0-9]{1,4}$/.test(valueParserParams.newValue) ? parseNumber(valueParserParams.newValue) : null;
  };

  _handleValueSetter = (valueSetterParams) => {
    const { restrictionType } = valueSetterParams.data;
    const { colId } = valueSetterParams.colDef;

    if (isString(valueSetterParams.newValue)) {
      valueSetterParams.newValue = this._handleValueParser(valueSetterParams);
    }

    if ((valueSetterParams.newValue !== null && valueSetterParams.newValue !== valueSetterParams.oldValue) ||
      (valueSetterParams.newValue === null && restrictionType === RestrictionTypeEnum.T))  {
        let cellData = getCellData(valueSetterParams);

        if (cellData == null && restrictionType === RestrictionTypeEnum.T) {
          cellData = RestrictionWeekCell.constructWeekForNewRow(colId, null);
          setCellData(valueSetterParams, colId, cellData);
        }

        cellData.updateNumberTotal(valueSetterParams.newValue);

        valueSetterParams.api.refreshCells({ columns: [valueSetterParams.colDef.colId], force: true });

        return true;
    }
    return false;
  };

  _restrictionTypeFormatter = (params, lsi) => {
    return (params.value !== RestrictionTypeEnum.Q) ? lsi.getLSIItem('PVT.ENUM_LABELS.RESTRICTION_TYPE_SHORT.'+ params.value) : '';
  }

  _restrictionTypeFilterFormatter = (params, lsi) => {
    return lsi.getLSIItem('PVT.ENUM_LABELS.RESTRICTION_TYPE.'+ params.value);
  }

  /**
   * Obsah tabulky - transformace z {@link Array.<model/RestrictionDTO>}
   *
   * @param {Array.<module:model/RestrictionDTO>} restrictions
   */
  static transformToRestrictionRows = (restrictions) => {
    if (!Array.isArray(restrictions)) {
      return [];
    }
    return restrictions.map(restrictionDTO => RestrictionRow.constructFromRestrictionDTO(restrictionDTO));
  };
}

export default RestrictionsTableData;
