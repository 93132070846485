import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
// import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
// import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
// import { MenuModule } from '@ag-grid-enterprise/menu';
// import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
// import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
// import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
// import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
// import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
// import { SideBarModule } from '@ag-grid-enterprise/side-bar';
// import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
// import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import PropTypes from 'prop-types';
import React from 'react';
import { AgGridReact } from 'ag-grid-react';

import { LsiContext } from '../../../../common/contexts';
import { usePrivilege } from '../../../hooks';
import { handleGetDefaultContextMenuItems } from '../index';
import leafRowOddEvenClassRules from '../rowClassRules/leafRowOddEvenClassRules';
import AGgridTooltip from '../components/AGgridTooltip/AGGridTooltip';

import './OvexAGTable.scss';
import { excelStyles } from './OvexAGTable.excelStyles';

ModuleRegistry.registerModules([
  ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule, ExcelExportModule, ClipboardModule
]);
const OvexAGTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);

  const { handleIsPrivileged } = usePrivilege();

  const classNames = ['ovex-ag-grid ag-theme-balham', props.className];
  if (props.headerNameRows > 1) {
    classNames.push('ovex-ag-grid--th-wrap');
  }
  if (props.highlightLeafOddEvenRows) {
    classNames.push('ovex-ag-grid--highlightLeafOddEvenRows');
  }

  const onGridReady = props.onGridReady;
  const handleGridReady = React.useCallback((params) => {
    onGridReady && onGridReady(params);
    ref && (ref.current = params);
  }, [onGridReady, ref]);

  const context = {
    ...props.agContext,
    agLsi: lsi,
    onAgIsPrivileged: handleIsPrivileged
  };

  const handleGetContextMenuItemsTrimSeparators = (params) => {
    if (props.getContextMenuItems == null) {
      return handleGetDefaultContextMenuItems(params, context.excelExportParams);
    }
    // remove *separator* item from first or last position
    const contextMenuItems = props.getContextMenuItems(params).filter(item => item != null);

    while (contextMenuItems[0] === 'separator') {
      contextMenuItems.shift();
    }
    while (contextMenuItems[contextMenuItems.length-1] === 'separator') {
      contextMenuItems.pop();
    }

    return contextMenuItems;
  };

  const handleLocaleTextFunc = (key, defaultValue) => {
    const localeText = lsi.getLSIItem('AG_GRID')[key];

    return localeText == null ? defaultValue : localeText;
  };

  const getRowClassRules = () => {
    return {
      ...props.rowClassRules,
      ...(props.highlightLeafOddEvenRows && leafRowOddEvenClassRules)
    };
  };

  const agGridExcelStyles = [
    ...(props.excelStyles || []),
    ...excelStyles
  ];

  const agGridFrameworkComponents = {
    ...props.frameworkComponents,
    AGgridTooltip: AGgridTooltip
  };

  const defaultColDef = {
    ...props.defaultColDef,
    tooltipComponent: 'AGgridTooltip'
  };

  return (
    <div
      className={classNames.join(' ')}
      style={{
        height: ['autoHeight', 'print'].includes(props.domLayout) ? '' : props.height,
        width: props.width
      }}
    >
      <AgGridReact
        alwaysShowVerticalScroll={props.alwaysShowVerticalScroll}
        animateRows={props.animateRows}
        autoGroupColumnDef={props.autoGroupColumnDef}
        columnDefs={props.columnDefs}
        columnTypes={props.columnTypes}
        context={context}
        defaultColDef={defaultColDef}
        defaultGroupOrderComparator={props.defaultGroupOrderComparator}
        detailCellRenderer={props.detailCellRenderer}
        detailRowHeight={props.detailRowHeight}
        domLayout={props.domLayout}
        embedFullWidthRows={props.embedFullWidthRows}
        enableFillHandle={props.enableFillHandle}
        enableRangeSelection={props.enableRangeSelection}
        excelStyles={agGridExcelStyles}
        frameworkComponents={agGridFrameworkComponents}
        getContextMenuItems={handleGetContextMenuItemsTrimSeparators}
        getDataPath={props.getTreeDataPath}
        getRowClass={props.getRowClass}
        getRowHeight={props.getRowHeight}
        gridOptions={props.gridOptions}
        groupDefaultExpanded={props.groupDefaultExpanded}
        groupDisplayType={props.groupDisplayType}
        groupHeaderHeight={props.groupHeaderHeight}
        groupHideOpenParents={props.groupHideOpenParents}
        groupIncludeFooter={props.groupIncludeFooter}
        groupIncludeTotalFooter={props.groupIncludeTotalFooter}
        groupRemoveLowestSingleChildren={props.groupRemoveLowestSingleChildren}
        groupRowAggNodes={props.groupRowAggNodes}
        groupRowRendererParams={props.groupRowRendererParams}
        groupSelectsChildren={props.groupSelectsChildren}
        headerHeight={16 * props.headerNameRows + 16} // 16 = text height per row | 16 = 8 + 8 (top, bottom "padding")
        isRowSelectable={props.isRowSelectable}
        localeTextFunc={handleLocaleTextFunc}
        masterDetail={props.masterDetail}
        // modules={AllModules}
        modules={[
          LicenseManager,
          ClipboardModule,
          ColumnsToolPanelModule,
          ExcelExportModule
          // FiltersToolPanelModule,
          // MasterDetailModule,
          // MenuModule,
          // RangeSelectionModule,
          // RichSelectModule,
          // RowGroupingModule,
          // ServerSideRowModelModule,
          // SetFilterModule,
          // MultiFilterModule,
          // AdvancedFilterModule,
          // SideBarModule,
          // StatusBarModule,
          // ViewportRowModelModule
        ]}
        onColumnGroupOpened={props.onColumnGroupOpened}
        onGridReady={handleGridReady}
        onGridSizeChanged={props.onGridSizeChanged}
        onRowDataChanged={props.onRowDataChanged}
        onRowSelected={props.onRowSelected}
        onSelectionChanged={props.onSelectionChanged}
        pinnedBottomRowData={props.pinnedBottomRowData}
        pinnedTopRowData={props.pinnedTopRowData}
        postSort={props.postSort}
        processCellFromClipboard={props.processCellFromClipboard}
        rememberGroupStateWhenNewData // FIXME - deprecated | ag-Grid: since v24.0, grid property rememberGroupStateWhenNewData is deprecated. This feature was provided before Transaction Updates worked (which keep group state). Now that transaction updates are possible and they keep group state, this feature is no longer needed.
        rowClassRules={getRowClassRules()}
        rowData={props.rowData}
        rowMultiSelectWithClick={props.rowMultiSelectWithClick}
        rowSelection={props.rowSelection}
        showOpenedGroup={props.showOpenedGroup}
        stopEditingWhenCellsLoseFocus
        suppressAggFuncInHeader
        suppressMenuHide
        suppressMovableColumns={props.suppressMovableColumns}
        suppressRowClickSelection={props.suppressRowClickSelection}
        suppressRowTransform={props.suppressRowTransform}
        suppressScrollOnNewData
        treeData={!!props.getTreeDataPath}
        {...(!!props.rowDataChangeDetectionStrategy && { rowDataChangeDetectionStrategy: props.rowDataChangeDetectionStrategy })} // if not set this use the default value of property of AgGridReact
      />
    </div>
  );
}));

OvexAGTable.propTypes = {
  agContext: PropTypes.object,
  alwaysShowVerticalScroll: PropTypes.bool,
  animateRows: PropTypes.bool,
  autoGroupColumnDef: PropTypes.object,
  className: PropTypes.string,
  columnDefs: PropTypes.arrayOf(PropTypes.object),
  columnTypes: PropTypes.object,
  defaultColDef: PropTypes.object,
  defaultGroupOrderComparator: PropTypes.func,
  detailCellRenderer: PropTypes.string,
  detailRowHeight: PropTypes.number,
  domLayout: PropTypes.oneOf(['normal', 'autoHeight', 'print']),
  embedFullWidthRows: PropTypes.bool,
  enableFillHandle: PropTypes.bool,
  enableRangeSelection: PropTypes.bool,
  excelStyles: PropTypes.arrayOf(PropTypes.object),
  frameworkComponents: PropTypes.object,
  getContextMenuItems: PropTypes.func,
  getRowClass: PropTypes.func,
  getRowHeight: PropTypes.func,
  getTreeDataPath: PropTypes.func,
  gridOptions: PropTypes.object,
  groupDefaultExpanded: PropTypes.number,
  groupDisplayType: PropTypes.string,
  groupHeaderHeight: PropTypes.number,
  groupHideOpenParents: PropTypes.bool,
  groupIncludeFooter: PropTypes.bool,
  groupIncludeTotalFooter: PropTypes.bool,
  groupRemoveLowestSingleChildren: PropTypes.bool,
  groupRowAggNodes: PropTypes.func,
  groupRowRendererParams: PropTypes.object,
  groupSelectsChildren: PropTypes.bool,
  headerNameRows: PropTypes.number,
  height: PropTypes.string,
  highlightLeafOddEvenRows: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  masterDetail: PropTypes.bool,
  onColumnGroupOpened: PropTypes.func,
  onGridReady: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
  onRowDataChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  pinnedBottomRowData: PropTypes.arrayOf(PropTypes.object),
  pinnedTopRowData: PropTypes.arrayOf(PropTypes.object),
  postSort: PropTypes.func,
  processCellFromClipboard: PropTypes.func,
  rowClassRules: PropTypes.object,
  rowData: PropTypes.arrayOf(PropTypes.object),
  rowDataChangeDetectionStrategy: PropTypes.string,
  rowMultiSelectWithClick: PropTypes.bool,
  rowSelection: PropTypes.oneOf(['single', 'multiple']),
  showOpenedGroup: PropTypes.bool,
  suppressMovableColumns: PropTypes.bool,
  suppressRowClickSelection: PropTypes.bool,
  width: PropTypes.string
};

OvexAGTable.defaultProps = {
  agContext: null,
  alwaysShowVerticalScroll: false,
  animateRows: false,
  autoGroupColumnDef: null,
  className: null,
  columnDefs: null,
  columnTypes: null,
  defaultColDef: null,
  defaultGroupOrderComparator: undefined,
  detailCellRenderer: null,
  detailRowHeight: 0,
  domLayout: 'normal',
  embedFullWidthRows: false,
  enableFillHandle: false,
  enableRangeSelection: false,
  excelStyles: null,
  frameworkComponents: null,
  getContextMenuItems: undefined,
  getRowClass: undefined,
  getRowHeight: undefined,
  getTreeDataPath: undefined,
  gridOptions: null,
  groupDefaultExpanded: 0,
  groupDisplayType: null,
  groupHeaderHeight: null,
  groupHideOpenParents: false,
  groupIncludeFooter: false,
  groupIncludeTotalFooter: false,
  groupRemoveLowestSingleChildren: false,
  groupRowAggNodes: undefined,
  groupRowRendererParams: undefined,
  groupSelectsChildren: false,
  headerNameRows: 1,
  height: '600px',
  highlightLeafOddEvenRows: false,
  isRowSelectable: null,
  masterDetail: false,
  onColumnGroupOpened: undefined,
  onGridReady: undefined,
  onGridSizeChanged: undefined,
  onRowDataChanged: null,
  onRowSelected: null,
  onSelectionChanged: null,
  pinnedBottomRowData: null,
  pinnedTopRowData: null,
  postSort: undefined,
  processCellFromClipboard: undefined,
  rowClassRules: null,
  rowData: null,
  rowDataChangeDetectionStrategy: null,
  rowMultiSelectWithClick: false,
  rowSelection: null,
  showOpenedGroup: false,
  suppressMovableColumns: false,
  suppressRowClickSelection: false,
  width: '100%'
};

export default OvexAGTable;
