import React from 'react';
import PropTypes from 'prop-types';
import { Events } from 'ag-grid-enterprise';
import { mdiCheck, mdiClose, mdiPencilOutline, mdiPlus } from '@mdi/js';
import { PGIBulkUpdateDTOITM } from '@ovex/price-guarantee-web-api';

import { IconButton } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import { useAlertBusOvex, useConfirmModal } from '../../../../../common/hooks';
import { isNonEmptyArray } from '../../../../../common/utils/arrays';
import { AlertTypeEnum } from '../../../../../common/objects';
import { ITMCreatePriceGuaranteeItemsPFC } from '../../../../privilege/context';
import { PGI_EDITABLE_COLUMN_ID_LIST } from '../PriceGuaranteeItemTable.columnIds';
import EditableModelGroupContext from '../EditableModelGroupContext';
import PriceGuaranteeItemRow from '../rowModel/PriceGuaranteeItemRow';

import {
  calculateModelGroupChildCount,
  discardChangesBeforeEditEnd,
  getVirtualRows,
  selectFirstInvalidCell
} from './ModelGroupRowInnerRenderer.helpers';

import './ModelGroupRowInnerRenderer.scss';

const propTypes = {
  api: PropTypes.object.isRequired, // property from AG Grid
  modelGroupMap: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired, // property from AG Grid
  onCancelEditModelGroup: PropTypes.func.isRequired,
  onSaveChanges: PropTypes.func,
  onSetEditableModelGroup: PropTypes.func,
  priceGuaranteeId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired // property from AG Grid
};

const defaultProps = {
  onSaveChanges: undefined,
  onSetEditableModelGroup: undefined
};

const iconSize = 0.72;

const ModelGroupRowInnerRenderer = (props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();
  const editableModelGroup = React.useContext(EditableModelGroupContext);
  const [ renderConfirmModal, handleShowConfirmModal ] = useConfirmModal(() => {});

  const api = props.api;
  const node = props.node;
  const modelGroup = props.value;
  const editable = modelGroup === editableModelGroup;
  const modelGroupData = props.modelGroupMap[modelGroup];

  const virtualRowList = React.useRef(getVirtualRows(node.allLeafChildren));

  const [ count, setCount ] = React.useState(calculateModelGroupChildCount(node.allLeafChildren));

  const handleRefresh = React.useCallback(
    () => {
      const c = calculateModelGroupChildCount(node.allLeafChildren);
      setCount(c);
    },
    [node]
  );

  React.useEffect(
    () => {
      api.addEventListener(Events.EVENT_ROW_DATA_CHANGED, handleRefresh);
      api.addEventListener(Events.EVENT_ROW_DATA_UPDATED, handleRefresh);
      return () => {
        api.removeEventListener(Events.EVENT_ROW_DATA_CHANGED, handleRefresh);
        api.removeEventListener(Events.EVENT_ROW_DATA_UPDATED, handleRefresh);
      };
    },
    [api, handleRefresh]
  );

  const handleRefreshCells = () => {
    api.refreshCells({
      rowNodes: node.allLeafChildren,
      columns: PGI_EDITABLE_COLUMN_ID_LIST,
      force: true
    });
  };

  const handleEditModelGroup = () => {
    if (props.onSetEditableModelGroup) {
      props.onSetEditableModelGroup(modelGroup, handleRefreshCells);
      api.setRowNodeExpanded(node, true);
    }
  };
  const handleCancelEditModelGroup = () => {
    props.onCancelEditModelGroup(handleRefreshCells);
  };

  const handleSaveChanges = (bulkUpdateDTO) => {
    props.onSaveChanges && props.onSaveChanges(bulkUpdateDTO);
  };

  const handleValidateAndSaveChanges = () => {
    api.stopEditing();
    const isSomeInvalid = node.allLeafChildren.some(nodeChild => !nodeChild.data.isValid());
    if (isSomeInvalid) {
      selectFirstInvalidCell(node.allLeafChildren, api);
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.PGI_INVALID_VALUES', AlertTypeEnum.WARNING);
      return;
    }
    const newItemList = [];
    const updateItemList = [];
    const deleteItemIdList = [];

    node.allLeafChildren.forEach(nodeChild => {
      const rowData = nodeChild.data;

      if (rowData.isModified()) {
        if (rowData.isRowStatusNew()) {
          newItemList.push(rowData.getNewDTO());
        } else if (rowData.isDeleteFlag()) {
          deleteItemIdList.push(rowData.id);
        } else {
          updateItemList.push(rowData.getUpdateDTO());
        }
      }
    });

    if (isNonEmptyArray(newItemList) || isNonEmptyArray(updateItemList) || isNonEmptyArray(deleteItemIdList)) {
      const bulkUpdateDTO = new PGIBulkUpdateDTOITM();
      isNonEmptyArray(newItemList) && (bulkUpdateDTO.newItemList = newItemList);
      isNonEmptyArray(updateItemList) && (bulkUpdateDTO.updateItemList = updateItemList);
      isNonEmptyArray(deleteItemIdList) && (bulkUpdateDTO.deleteItemIdList = deleteItemIdList);

      if (isNonEmptyArray(deleteItemIdList)) {
        handleShowConfirmModal({
          confirmModalProps: {
            content: lsi.getLSIItem('PRICE_GUARANTEE.CONFIRM_MESSAGE.PGI_DELETE'),
            onConfirm: () => handleSaveChanges(bulkUpdateDTO)
          }
        });
      } else {
        handleSaveChanges(bulkUpdateDTO);
      }
    } else {
      handleCancelEditModelGroup();
    }
  };

  const handleAddNewRow = () => {
    const newRow = PriceGuaranteeItemRow.createNewRow(props.priceGuaranteeId, modelGroup);
    api.applyTransaction({
      add: [newRow],
      remove: virtualRowList.current
    });
  };

  const handleCancelEditModelGroupAndDiscardChanges = () => {
    const isSomeModified = node.allLeafChildren.some(nodeChild => nodeChild.data.isModified());
    if (isSomeModified) {
      handleShowConfirmModal({
        confirmModalProps: {
          content: lsi.getLSIItem('PRICE_GUARANTEE.CONFIRM_MESSAGE.PGI_DISCARD_CHANGES'),
          onConfirm: () => {
            discardChangesBeforeEditEnd(node.allLeafChildren, api, virtualRowList.current);
            handleCancelEditModelGroup();
          }
        }
      });
    } else {
      handleCancelEditModelGroup();
    }
  };

  return (
    <div
      className='ovex-agg-ModelGroupRowInnerRenderer'
    >
      <div
        className='ovex-agg-ModelGroupRowInnerRenderer-actions'
      >
        <IconButton
          hidden={editable || editableModelGroup != null}
          iconPath={mdiPencilOutline}
          iconSize={0.64}
          onClick={handleEditModelGroup}
          privilegeFunctionalityContext={new ITMCreatePriceGuaranteeItemsPFC(props.priceGuaranteeId)}
        />
        <IconButton
          hidden={!editable}
          iconColor='#0090d7'
          iconPath={mdiPlus}
          iconSize={iconSize}
          onClick={handleAddNewRow}
        />
        <IconButton
          hidden={!editable}
          iconColor='#4ba82e'
          iconPath={mdiCheck}
          iconSize={iconSize}
          onClick={handleValidateAndSaveChanges}
        />
        <IconButton
          hidden={!editable}
          iconPath={mdiClose}
          iconSize={iconSize}
          onClick={handleCancelEditModelGroupAndDiscardChanges}
        />
      </div>
      <div
        className='ovex-agg-ModelGroupRowInnerRenderer-label'
      >
        <strong>{modelGroup}</strong>
        {modelGroupData ? ` - ${modelGroupData.name} ` : ' '}
        {`(${count})`}
      </div>
      {renderConfirmModal()}
    </div>
  );
};

ModelGroupRowInnerRenderer.propTypes = propTypes;
ModelGroupRowInnerRenderer.defaultProps = defaultProps;

export default ModelGroupRowInnerRenderer;
